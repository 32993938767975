@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

@font-face {
  font-family: "a Abstract Groovy";
  font-weight: 400;
  src: local("a Abstract Groovy"), url("../assets/fonts/aAbstractGroovy.otf") format("opentype");
}

@font-face {
  font-family: "Quaestrial";
  font-weight: 400;
  src: local("Questrial"), url("../assets/fonts/Questrial-Regular.otf") format("opentype");
}
