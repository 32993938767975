@import "@styles/mixins";

.content {
  margin-top: 103px;
  padding-left: 97px;
  padding-right: 225px;
  width: 100%;

  @include tablet {
    display: grid;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 50px;
  }

}

.title {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;

  color: #7EFBEF;
  @include tablet {
    font-size: 25px;
    margin-top: 20px;
  }
  @include laptop {
      font-size: 28px;
  }
}

a {
  color: var(--primary-color);
}