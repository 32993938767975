@import "@styles/mixins";

.mainContent {

    padding-bottom: 3rem;
    margin-top: -50px;
}

.content {
    display: flex;
    padding: 50px;
    gap: 92px;
    width: 100%;
    margin-bottom: 30px;
    justify-content: center;

    @include tablet {
        display: contents;
    }
}

.columnHeader {
    display: flex;
    gap: 33px;
    margin-left: 10px;
    & .filterBtn {
        height: 37px;
        border-radius: 0px;
        font-size: 17px;
        align-items: center;
        display: flex;
        border-radius: 5px 5px 0px 0px;
        background-color: #000000;
        color: white;
        border: 1px solid #7EFBEE;
        &_selected {
            background-color: #7EFBEE;
            color: #000000;
            border: 0;
        }
        @include laptop {
            height: 28px;
            font-size: 10px;
        }
    }

    @include laptop {
        gap: 21px;
    }
}
.title {
    display: grid;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    font-family: "Questrial";
    font-size: 51px;
    text-transform: uppercase;
    @include tablet {
      font-size: x-large;
    }
    @include mobile {
      font-size: large;
    }
    
    @include laptop {
      font-size: 28px;
    }
  }

.titleButtons {
    margin: auto;
    margin-top: 73px;
    gap: 64px;
    display: flex;
    .btns {
      font-size: 30px;
      height: 68px;
      background: #000000 0% 0% no-repeat padding-box;
      border: 1px solid #7EFBEF;
      border-radius: 100px;
      color: white;
      font-weight: 600;
      &_selected {
        background: #7EFBEF 0% 0% no-repeat padding-box;
        border: 1px solid #7EFBEF;
        border-radius: 100px;
        color: black;
      }
      @include laptop {
        height: unset;
        font-size: 14px;
        padding: 10px 14px;
      }
  
    }
    @include laptop {
      margin-top: 40px;
      gap: 40px;
    }
  }

.leftColumn {
    padding: 0px 10px 10px 10px;
    perspective: 100px;
    width: 950px;

    @include tablet {
        flex: 100%;
    }
    @include laptop {
        width: 600px;
    }
}

.rightColumn {
    padding: 10px;
    width: 472px;

    @include tablet {
        flex: 100%;
    }
    @include laptop {
        width: 238px;
        padding: 0px;
    }
}

.btn {
    margin: auto;
    font-size: 25px;
    font-weight: bold;
    height: 62px;
    text-align: center;
    width: 200px;
    border-radius: 100px;
    margin-top: 20px;

    &_creating {
        cursor: not-allowed !important;
    }

    @include laptop {
        width: 115px;
        height: 31px;
        font-size: 11px;
        margin-top: 5px;
        padding: unset;
    }
}

.customPoolWrapper {
    background: #000000 0% 0% no-repeat padding-box;
    border: 2px solid #7EFBEF;
    border-radius: 5px;
    width: 472px;
    height: 588px;
    margin-top: 30px;
    font-family: 'Questrial';
    & h3 {
        margin-top: 25px;
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        text-transform: uppercase;

        @include laptop {
            margin-top: 7px;
            font-size: 11px;
        }
    }

    @include laptop {
        width: 238px;
        height: 300px;
    }
}

.notLogged  {
    border-radius: 5px;
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    width: 472px;
    height: 588px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    margin-left: -2px;
    margin-top: -2px;
    & img {
        width: 54px;
        height: 54px;
    }

    @include laptop {
        width: 238px;
        height: 300px;
    }
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;


}

.formError {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.125rem;
    color: var(--error-color);
    text-align: center;
}

.formRow {
    align-items: center;
    width: 100%;
    text-align: center;
    display: contents;
    & h4 {
        margin-top: 26px;
        font-size: 19px;
        text-align: center;
        text-transform: uppercase;

        @include laptop {
            margin-top: 10px;
            font-size: 9px;
        }
    }

    & input {
        background: #000000 0% 0% no-repeat padding-box;
        border: 1px solid #7EFBEF;
        width: 70%;
        margin-top: 20px;
        font-size: medium;
        text-align: center;
        border-radius: 5px;

        @include laptop {
            margin-top: 8px;
            width: 83px;
            font: normal normal normal 9px/9px Questrial;
            letter-spacing: 0px;
            height: 19px;
        }
    }
}

.privateBtn {
    padding: 0px;
    margin-top: 35px;
    width: 208px;
    height: 59px;
    display: flex;
    justify-content: start;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #7EFBEE;
    border-radius: 30px;

    & .privateSp {
        font-weight: 600;
        width: 109px;
        height: 55px;
        /* UI Properties */
        background: #7EFBEE 0% 0% no-repeat padding-box;
        border: 2px solid #000000;
        border-radius: 30px;
        text-transform: uppercase;display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;

        &_passive {
            background: unset;
            border: unset;
            color: white;
        }

        @include laptop {
            width: 53px;
            height: 27px;
            font-size: 9px;
        }
    }

    
    &_private {
        justify-content: end;
    }

    @include laptop {
        width: 104px;
        height: 31px;
        margin-top:10px;
    }
}

.tokens {
    display: flex;
    gap: 20px;
    margin: auto;
    margin-top: 15px;

    .tokenButton {
        border: 1px solid #7EFBEF;
        border-radius: 3px;
        width: 51px;
        height: 51px;
        font-size: 29px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include laptop {
            margin-top: 0px;
            width: 25px;
            height: 25px;
            font-size: 14px;
        }
    }

    @include laptop {
        margin-top: 9px;
        gap: 12px;
    }
}

.actionButtons {
    display: flex;
    margin-top: 55px;

    &_creaing {
        cursor: not-allowed;
    }
}

.popover {
    right: -50px;
    position: absolute;
    top: -10px;
    width: 317px;
    padding: 24px;
    z-index: 9;
    box-shadow: 0 2.5rem 4rem -1.5rem rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: all 0.2s;
    background: #000000 0% 0% no-repeat padding-box;
    border: 2px solid #7EFBEF;
    display: block;
    font-family: "Questrial";
    text-align: center;

    & h4 {
        margin: 0px auto;
        text-transform: uppercase;
        font-size: 19px;
    }

    & img {
        margin: auto;
        margin-top: 38px;
        width: 88px;
        height: 88px;
    }

    & .generatedLink {
        margin: auto;
        margin-top: 47px;
        width: 220px;
        height: 39px;
        background: #000000 0% 0% no-repeat padding-box;
        border: 2px solid #7EFBEF;
        opacity: 1;
        text-align: center;
        display: grid;
        font-size: 18px;
        align-items: center;

    }

    & p {
        margin: auto;
        margin-top: 19px;
        font-size: 19px;
        width: 208px;
        height: 40px;
    }

    & .copyBtn {
        margin: auto;
        margin-top: 26px;
        width: 127px;
        height: 53px;
        /*background: #7EFBEF 0% 0% no-repeat padding-box;
            border: 1px solid #000000;*/
        border-radius: 45px
    }
}

.joinBtn {
    font: normal normal normal 18px/19px Questrial;
    letter-spacing: 0px;
    color: #FF367C;
    &_positive {
        color: green;
    }

    @include laptop {
        font-size: 11px;
    }
}

.overLogging {
    width: 433px;
    height: 472px;
    position: absolute;
    text-align: center;
    display: inline-flex;
    background-color: #00758a99;
    /* opacity: 0.7; */
    justify-content: center;
    align-items: center;
    align-content: center;

}

.tableBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &>.tableBtn {
        padding: 5px 7px;
        height: 38px;
        line-height: 0;
        border-width: 1px;
        padding: 0px 10px;
        & img {
            width: 20px;
        }

        @include laptop {
            height: 20px !important;
            font-size: 15px;
            & img {
                width: 10px;
            }
            padding: 3px 5px;
        }
    }
}

.rangeInput {
    margin-top: 10px;
    align-self: center;
    justify-content: center;
    display: inline-block;
    width: 165px;

    &>.rc-slider-handle {
        background: var(--secondary-gradient);
        width: 20px !important;
        height: 0.5625rem;
        border: 0.125rem solid #758958;
        margin-top: -0.25rem;

        &-dragging {
            box-shadow: none !important;
        }
    }
}

:global {
    & .rc-slider-rail {
        background: #d2d2d2;
        height: 0.0625rem;
        border-radius: 0.0625rem;
        cursor: pointer;
    }

    & .rc-slider-track {
        background: var(--secondary-gradient);
        border-radius: 0.0625rem;
        height: 0.0625rem;
        cursor: pointer;
    }


}

.text_negative {
    background: #FF367C  0% 0% no-repeat padding-box;
    border-radius: 17px;
    border-width: 0px;
    width: unset !important;
    &:hover {
        color: red;
        background-color: #9c1444;
    }
}


.privateLabel {
    margin-top: 5px;
    color: var(--primary-color)
}
.rangeMarks {
    width: 194px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    // margin-left: -0.3rem;
    // margin-right: -0.3rem;
  
    & > span {
      cursor: pointer;
      padding: 0 0.1rem;
    }
  }
  
.selected {
    font-weight: 700;
    font-size: 30px !important;
  }