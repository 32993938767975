@import "@styles/mixins";

.container {
    display: flex;
    justify-content: center;
    font-family: 'Questrial';
    flex-direction: column;
    .tabs {
        display: flex;
        flex-direction: row;
        gap: 70px;
        justify-content: center;

        & > .tabBtn {
            text-transform: uppercase;
            border: 2px solid #7EFBEE;
            border-radius: 34px ;
            color: #7EFBEE;
            font-size: 40px;
            width: 385px;
            height: 68px;
            background: black;
            font-weight: 600;
            &_selected {
                background: #7EFBEE 0% 0% no-repeat padding-box;
                color: black;
            }

            @include laptop {
                padding: unset;
                font-size: 14px;
                width: 150px;
                height: 37px;
            }
        }

        @include laptop {
            gap: 28px;
        }
    }
    .content {
        margin-top: 50px;
        width: 1424px;
        .logTable {
            .title {
                width: 100%;
                height: 97px;
                letter-spacing: 0px;
                font-weight: 600;
                color: #7EFBEE;
                text-shadow: 0px 1px 3px #00000066;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                font-size: 65px;
                background: #000000 0% 0% no-repeat padding-box;
                border-radius: 25px 25px 0px 0px;
                border: 1px solid #7EFBEE;

                @include laptop {
                    height: 40px;
                    font-size: 30px;
                }
                
            }
            &_small {
                width: 706px;
            }
            @include laptop {
                &_small {
                    width: 277px;
                }
            }
        }
        .details {
            margin-top: -6px;
            display: flex;
            gap: 11px;
            justify-content: space-between;
            flex-wrap: wrap;
            .title {
                font-size: 32px;
                width: 706px;
                height: 48px;
                @include laptop {
                    width: 277px;
                    height: 25px;
                    font-size: 15px;
                }
            }
        }

        @include laptop {
            width: 590px;
            margin-top: 34px;
        }
    }
    
    .detailBtn {
        margin: auto;
        font-size: 40px;
        font-weight: 600;
        text-transform: uppercase;
        color: #000000;
        width: 492px;
        height: 75px;
        background: #7EFBEE 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 10px #7EFBEE;
        border: 2px solid #000000;
        border-radius: 0px 0px 40px 40px;
    
        &_hide {
            visibility:hidden;
        }

        @include laptop {
            font-size: 18px;
            width: 200px;
            height: 30px;
            padding: unset;
        }
    }
}