@import "@styles/mixins";

.globalHeader{
  width: 100%;
  padding: 0px 0px 87px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.header {
  width: 100%;
  padding: 0px 0px 87px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
  
.headerContent {
  width: 100%;
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  background-color: black;
}
.userContent {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  & .balanceContent {
    display: flex;
    flex-direction: row;
  }
}

.logo {
  width: 250px;
  height: 29px;
  margin-left: 47px;
  margin-bottom: 26px;
  @include tablet {
    width: 150px;
    margin-left: 30px;
    margin-bottom: unset;
  }
}

.user {
  display: flex;
  align-items: center;
  position: relative;
  &_active_popover {
    .popover {
      visibility: visible;
      opacity: 1;
    }
  }
  &_active_notification {
    .notification {
      visibility: visible;
      opacity: 1;
    }
  }

  & span {
    font-family: "Questrial";
    font-style: normal;
    font-size: 17px;
    margin-right: 15px;
    letter-spacing: 0px;
    color: #fff;
  }
}
.smallImage {
  & > img {
    width: 26px !important;
    margin-right: 7px;
    margin-left: 7px;
  }
}
.userBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  & .profileAtc {
    margin-right: 12px;

    & > img {
      width: 40px;

      @include tablet {
        width: 28px;
        margin-right: 0;
      }
    }
    & .stars {
      width: 40px;
      height: 13px;
      gap: 2px;
      display: flex;
      & .star {
        
        width: 13px;
        height: 13px;
        &  img {
          
        width: 13px;
        height: 13px;
        }

      }
    }
  }
  

  @include tablet {
    margin-left: 12px;

    & span {
      display: none;
    }
  }
}

.userDivider {
  margin-bottom: -4px;
  @include tablet {
    display: none;
  }
}
.notification { 
  position: absolute;
  top: calc(100%);
  right: 200px;
  height: 400px;
  width: 363px;
  opacity: 1;
  background-color: #00000070;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 25px;
  border: 1px solid #7EFBEE;
  padding: 15px;
  z-index: 9;
  box-shadow: 0 2.5rem 4rem -1.5rem rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;

  & .notificationTitle {
    text-transform: uppercase;
    font-size: 34px;
    border-bottom: 5px solid #7EFBEE;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    @include laptop {
      font-size: 11px;
      border-bottom-width: 2px;
      margin-bottom: 5px;
    }
  }
  & .notificationList {
    height: 310px;
    overflow-y: auto;
    & > li {
      opacity: 1;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      border: 1px solid #7EFBEE;
      border-radius: 10px;
      margin-bottom: 5px;
      min-height: 56px;
      padding: 10px;
      padding-bottom: 0px;

      & .time {
        color: #7EFBEE;
        font-size: 10px;
        width: 100%;
        text-align: end;
        @include laptop {
          font-size: 5px;
        }
      }

      @include laptop {
        min-height: 30px;
        font-size: 7px;
        padding: 5px;
      }
    }
    @include laptop {
      height: 170px;
    }
  }
  @include tablet {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    border: unset;
    background: var(--fourth-gradient);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
  }

  @include laptop {
    width: 194px;
    height: 213px;
    padding: 9px;
  }
  &__mobileHeader {
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    display: none;

    @include tablet {
      display: block;
    }

    & > img {
      width: 112px;
      display: block;
      margin: 0 auto;
    }
  }

  &__mobileClose {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border: none;
    cursor: pointer;
    border-radius: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--primary-color);
    font-size: 2rem;
    line-height: 2rem;
  }

  &__mobileFooter {
    display: none;
    margin-top: auto;

    @include tablet {
      display: block;
    }
  }
}
.popover { 
  position: absolute;
  top: calc(100%);
  right: 0;
  width: 181px;
  background-color: #00000070;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 0 0 25px 25px;
  border: 3px solid #7EFBEE;
  padding: 0px 10px 10px 10px;
  z-index: 9;
  box-shadow: 0 2.5rem 4rem -1.5rem rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;

  @include tablet {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    border: unset;
    background: var(--fourth-gradient);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
  }

  &__mobileHeader {
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    display: none;

    @include tablet {
      display: block;
    }

    & > img {
      width: 112px;
      display: block;
      margin: 0 auto;
    }
  }

  &__mobileClose {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border: none;
    cursor: pointer;
    border-radius: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--primary-color);
    font-size: 2rem;
    line-height: 2rem;
  }

  &__mobileFooter {
    display: none;
    margin-top: auto;

    @include tablet {
      display: block;
    }
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.refreshBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 100%;
  }

  &:hover {
    & > svg path {
      fill: var(--primary-color);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &_rotate {
    animation: rotation 2s infinite linear;
  }
  @include tablet {
    margin-right: 14px;
  }
}

.depositBtn {
  width: 110px;
  height: 46px;
  font-size: 20px;
  font-family: "Questrial";
  font-weight: bolder;
  margin-right: 20px;
  @include tablet {
    right: 0;
    bottom: -2.8rem;
  }
}

.balance {
  margin-top: 5px;
}

.connectBtn {
  width: 142;
  height: 50px;
  font-size: 20px;
  font-family: 'Questrial';
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0px 1px 3px #00000066;
  font-weight: 600;
}

.depositBtn {
  width: 142;
  height: 40px;
  font-size: 20px;
  font-family: 'Questrial';
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0px 1px 3px #00000066;
  font-weight: 600;
}