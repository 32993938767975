@import "../../../../styles/mixins";

.main {
  padding: 0 80px;

  @include tablet {
    padding: 178px 0 84px 0;
    margin: 0 22px;
    margin-top: 0;
    position: relative;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    @include tablet {
      justify-content: center;
      display: grid;
    }
    .leftWrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 150px;
      @include tablet {
        margin-right: 0;
        width: 100%;
        display: block;
      }

      @include laptop {
        
        width: 45%;
        padding-right: 0px;
      }
      .title {
        text-transform: uppercase;
        color: white;
        text-shadow: 0px 3px 3px #000000;
        font: normal normal normal 78px 'Questrial', sans-serif;

        @include tablet {
          margin-top: 47px;
          width: 100%;
        }
        @include laptop {
          font-size: 40px;
        }
      }
      .leftSubWrapper{

        .list {
          margin: 38px 0 0 28px;
          list-style-type: square;

          & > li {
            text-transform: uppercase;
            margin-bottom: 50px;
            text-shadow: 0px 3px 6px #00000066;
            font: normal normal normal 35px/36px 'Questrial', sans-serif;
            letter-spacing: 0;
            color: #FFFFFF;
            list-style-image: url('../../../../assets/icons/li-icon.svg');

            @include laptop {
              font-size: 18px;
              margin-top: 35px;
              width: 343px;
              height: 95px;
            }
          }
          & > li:last-child {
            margin-bottom: 0;
          }
        }
        .buttons{
          margin-top: 50px;
          display: flex;
          align-items: center;
          gap: 40px;
          .mainButton {
            box-shadow: 0px 3px 6px var(--primary-shadow-color);
            text-transform: uppercase;
            font-weight: 700;
            box-shadow: 0px 3px 6px #00000079;
            border-radius: 61px;
            border: unset;
            @include laptop {
              font-size: 18px;
              border-radius: 61px;
              padding: 12px 31px;
              width: 171px;
            }
          }
          
          .subButton {
            box-shadow: 0px 0px 6px #7EFBEF;
            border: unset;
            @include laptop {
              font-size: 18px;
              border-radius: 51px;
              padding: 12px 31px;
              width: 175px;
            }
          }
        }
      }
    }
    .rightWrapper {
      width: 50%;
      height: max-content;

      .image {
        width: 100%;
        height: auto;

        @include tablet {
          position: absolute;
          top: 48px;
          left: 50%;
          transform: translateX(-50%);
          max-width: 330px;
          opacity: 0.5;
          z-index: -1;
        }
      }
    }
  }
}

