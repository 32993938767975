@import "@styles/mixins";

.change {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: var(--positive-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &_prepare {
    font-size: 21px;
    color: white;
  }
  &_negative {
    color: var(--negative-color);
  }

  & > svg {
    margin-right: 0.1875rem;
    width: 0.5rem;
  }

  @include laptop {
      font-size: 9px !important;
      &_prepare {
        font-size: 9px;
        color: white;
      }
  }
}
