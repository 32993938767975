.wrapper {
  display: flex;
  align-items: center;

  &_hidden {
    visibility: hidden;
    pointer-events: none;
  }
}

.btn {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;

  & > img {
    width: 100%;
  }
}

.musicIcon {
  width: 1.2rem;
}
