@import "@styles/mixins";

.wrapper {
    height: 200px;
    width: 280px;
    font-family:  'Questrial';

    @include laptop {
        width: 150px;
        height: 110px;
    }
}

.title {
    font-size: 38px;
    letter-spacing: 0px;
    color: #7EFBEE;
    opacity: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 40px;
    margin-top: 14px;
    text-transform: uppercase;

    @include laptop {
        font-size: 19px;
        height: 35px;
        align-items: center;
    }

}
.content {
    margin-top: 14;
    width: 100%;
    height: 129px;
    background: #7EFBEE 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 49px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 500;

    &_bigFont {
        font-size: 79px;
    }

    @include laptop {
        height: 70px;
        font-size: 23px;
        margin-top: 0px;

        &_bigFont {
            font-size: 40px;
        }
    }
}