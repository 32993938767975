@import "@styles/mixins";

.timer {
  width: 119px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7EFBEF 0% 0% no-repeat padding-box;
  border: 2px solid #7EFBEF;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: black;
  font-size: 30px;
  font-family: "Questrial";
  color: black;

  @include laptop {
    width: 58px;
    height: 38px;
    font-size: 18px;
  }

  &_game {
    width: 240px;
    height: 93px;
    text-align: center;
    align-items: center;
    display: grid;
    margin: 0;
    @include  laptop{
      width: 97px;
      height: 77px;
      background: #05141D 0% 0% no-repeat padding-box;
      border: 2px solid #7EFBEF;
      border-radius: 5px;
      color: #7EFBEF;
    }
  }
}
.title {
  margin-bottom: -10px;
}
.time {
  margin-top: -10px;
}

