@import "@styles/mixins";

.content {
    display: flex;
    padding: 50px;
    width: 100%;
    @include tablet {
        display: contents;
    }
    margin-top: -30px;
    justify-content: space-around;
    }
    
    .title {
        flex: 1;
        display: block;
        margin: 0px auto;
        text-align: center;
        font-size: 59px;
        text-transform: uppercase;
        
        @include laptop {
            font-size: 29px;
        }
        @include tablet {
        font-size: x-large;
        }
        
        @include mobile {
            font-size: large;
        }
        }
.tables {
    height: 410px;
}
.subTitle {
    display: block;
    margin: auto;
    text-align: center;
    font-weight: 700;
    font-family:  "Questrial";
    letter-spacing: 0px;
    color: #7EFBEF;
    text-transform: uppercase;
    margin-bottom: 40px;
    font-size: 24px;
    margin-top: 40px;
    
    @include laptop {
        font-size: 18px;
        margin-top: 35px;
    }
}
    .column {
        font-family: "Questrial";
        flex: 0.33;
        padding: 10px;
         text-align: center;
        @include tablet {
            flex: 100%;
        }
    }
.profileImage {
    margin-top: 8px;
    width: 211px;
    height: 211px;
    @include laptop {
        width: 130px;
        height: 130px;
    }
}
.profileName {
    font-size: 35px;
    margin-top: 19px;
    @include laptop {
        font-size: 21px;
        margin-top: 13px;
    }
}
.profileBalance {
    text-transform: uppercase;
    font-size: 35px;
    margin-top: 19px;
    @include laptop {
        font-size: 21px;
        margin-top: 7px;
    }
}
.profileInfo {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & p {
        color: white;
        font-size: 24px;
        @include laptop {
            font-size: 14px;
        }
    }
    & hr {
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid #7EFBEF;
        width: calc(60%);
        @include laptop {
            margin-top: 6px;
            margin-bottom: 6px;
            width: 176px;
        }
    }
    @include laptop {
        margin-top: 11px;
    }
}
.buttonWrapper {
    display: grid;
    justify-content: center;
    margin-top: 26px;
    @include laptop {
        margin-top: 24px;
    }
}
.refWrapper {
    display: grid;
    justify-content: center;
    margin-top: 30px;


    & .refTitle {
        font-size: 23px;

        @include laptop {
            font-size: 14px;
        }
    }

    & .refBtns {
        padding-left: 50px;
        margin-top: 12px;
        display: flex;
        gap: 11px;
        text-align: center;
        align-items: center;
        justify-content: center;
        & .refBtn {
            width: 199px;
            height: 45px;
            /* UI Properties */
            background: #000000 0% 0% no-repeat padding-box;
            border: 1px solid #7EFBEF;
            opacity: 1;
            font-size: 20px;
            font-weight: 600;
            color: white;
            text-transform: uppercase;
            text-align: center;
            align-content: center;
            display: grid;

            @include laptop {
                width: 124px;
                height: 28px;
                font-size: 16px;
            }
        }

        & .refBtnCpy {
            width: 38px;
            height: 38px;
            background-color: var(--primary-border-color);
            border-radius: 100%;
            padding: 5px;
            border-width: 0px;
            display: flex;
            align-content: center;
            align-items: center;
            font-size: 20px;

            @include laptop {
                width: 23px;
                height: 23px;
                font-size: 17px;
                padding: 5px 0px;
            }
        }

        @include laptop {
            margin-top: 5px;
        }
    }
    & > p {
        font-size: 20px;
        color: white;
        margin-top: 18px;
        width: 396px;
        height: 40px;

        @include laptop {
            width: 218px;
            height: 22px;
            font-size: 11px;
            margin-top: 11px;
        }
    }

    @include laptop {
        margin-top: 26px;
    }
}
.profileButton {
    flex: 1;
    margin-top: 20px;
    border-radius: 0;
    width: 160px;
    height: 46px;
    /* UI Properties */
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #7EFBEF;
    border-radius: 23px;
    color: white;
    font-size: 20px;

    @include laptop {
        width: 99px;
        height: 28px;
        font-size: 11px;
        padding: 0px;
        margin-top: 13px;
    }
}
.liner {
    margin-top: 8px;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > .star {
        width: 23px;
        height: 23px;
    }
    & .bar {
        width: 206px;
        height: 12px;
        /* UI Properties */
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 15px;
    }
    & > .info {
        width: 36px;
        height: 36px;
        color: #7EFBEF;
    }
}

.popover { 
    left: calc(50% - 400px);
    position: absolute;
    bottom: calc(50% - 100px);
    width: 417px;
    height: 104px;
    padding: 24px;
    z-index: 9;
    box-shadow: 0 2.5rem 4rem -1.5rem rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: all 0.2s;
    background: #000000 0% 0% no-repeat padding-box;
    border: 2px solid #7EFBEF;
    display: block;
    font-family: "Questrial";
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    color: #7EFBEF;
    display: flex;

}