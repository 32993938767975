@import "@styles/mixins";
.roundsMap {
    

    &_game {
        backdrop-filter: blur(30px);
        margin-top: 250px;
    }
}
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 576px;
    height: 501px;
    padding: 20px;
    background: #000000 0% 0% no-repeat padding-box;
    border: 2px solid #7EFBEF;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 15px;
    &_lose {
      border-color: #FF367C
    }
  }
.mainContainer{
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin-top: -80px;
    margin-left: -70px;
    font-family: 'Questrial';
    
    & > li {
        & > span {
            float: right;
            margin-right: 5px;
        }
        & > h4 {
            font-weight: 500;
            color: aqua;
            font-size: 25px; 
        }
    }
  }

  .round{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:165px;
    list-style:none;
    padding:0;
    margin-left: 35px;

    &_right {
        margin-left: unset;
        margin-right: 35px;
    }

    &_game {
        margin-top: 0px;
    }

    @include laptop {
        margin-left: -15px;
        &_right {
            margin-left: unset;
            margin-right: -15px;
        }
    }
  }
  

  .roundSpacer{ flex-grow:1; 
    &_final {
        margin-top: 50%;
    }
}
  .roundSpacer:first-child,
  .roundSpacer:last-child{ flex-grow:.5; 
@include laptop {
    flex-grow: .5;
}
}
.game {
    padding-left: 20px;
}
.player {
    text-align: center;
    width: 165px;
    height: 54px;
    /* UI Properties */
    background: #000000 0% 0% no-repeat padding-box;
    border: 2px solid #7EFBEF;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    margin-left: 40px;

    @include laptop {
        width: 90px;
        height: 29px;

        &_final {
            margin: auto;
        }
    }

    &_mine {
        background-color: #7EFBEF;
        color: #000000;
        cursor: pointer;
    }

    &_final {
        margin-left: unset;
    }

    &_right {
        margin-left: unset;
        margin-right: 40px;
    }
    
    &_eliminated {
        text-decoration: line-through;
    }
}
.winner {
        font-weight: 700;
    }
body{
  font-family:sans-serif;
  font-size:small;
  line-height:1.4em;
}
.gameSpacer {
    flex-grow:1;
    justify-content: center;
    display:flex;
    align-items: center;
    border-top:2px solid #7EFBEF;
    border-right:2px solid #7EFBEF;
    border-bottom:2px solid #7EFBEF;
    min-height:120px;
    width:200px;
    margin-left: 45px;
    margin-top: calc(-15%);
    margin-bottom: calc(-18%);
    z-index: 1;

    &_right {
        border-right:unset;
        border-left:2px solid #7EFBEF;
        margin-left: unset;
        margin-right: 45px;
    }

    @include laptop {
            width: 150px;
            margin-top: calc(-10%);
            margin-bottom: calc(-10%);

    }
}

.finalSpacer {
    width: 600px;
    margin-left: 80px;

    @include laptop {
        margin-left: 28px;
        width: 400px;
    }
}
.finalGame {
    width: 165px;
    position: relative;
    @include laptop {
        width: unset;
    }
}
.finalGameSpacer {
    margin-top: 25px;
    width: 600px;
    justify-content: center;
    justify-content: center;
    display:flex;
    align-items: center;
    @include laptop {
        width: 400px;
    }
}
.final {
    height: 30px;
    display: flex;
    justify-content: space-around;
    @include laptop {
        height: 15px;
    }
}
.finalContainer {
    width: 600px;
    display: flex;
    justify-content: space-around;
    border-bottom:2px solid #7EFBEF;
    @include laptop {
        width: 400px;
    }
}

.mapTitle {
    font-family: 'Questrial';
    justify-content: center;
    display: flex;
    color: white;
    align-content: center;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    margin-top: -150px;
    &_title{
        font-size: 25px;
        font-weight: 600;

    }

    & > h2 {
        margin-top: 50px;
        font-size: 30px;
        font-weight: 400;
    }
    margin-left: 20px;
    @include laptop {
        margin-left: -35px;
        margin-top: -200px;
        margin-bottom: 50px;
    }
}

.container {
    margin-top: 100px;
}

.mapClose {
    position: absolute;
    font-size: 35px;
    font-weight: 600;
    text-transform: uppercase;
    align-content: end;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    float: right;
    top: -50px;
    right: 50px;
    color: var(--primary-color);
    cursor: pointer;
    @include laptop {
        top: unset;
    }
}