@import "@styles/mixins";

.Navigation {
  & ul{
    display: flex;
    align-items: center;
    justify-content: space-between;


    & li{
      margin: 0 39px;
      font-size: 20px;
      font-family: 'Questrial', sans-serif;
      cursor: pointer;
      text-transform: uppercase;

      &:hover {
        color: var(--primary-color);
      }
      @include laptop {
        margin: 0.75rem 35px;
      }

      @include tablet {
        display: none;
      }
    }
  }
}


.logo {
  width: 10rem;

  @include tablet {
    width: 112px;
  }
}

.user {
  display: flex;
  align-items: center;
  position: relative;

  &_active {
    .popover {
      visibility: visible;
      opacity: 1;
    }
  }

  & span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #fff;
  }
}

.userBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > img {
    width: 1.5rem;
    margin-right: 0.5rem;

    @include tablet {
      width: 28px;
      margin-right: 0;
    }
  }

  @include tablet {
    order: 1;
    margin-left: 12px;

    & span {
      display: none;
    }
  }
}

.userDivider {
  margin: 0 0.75rem;
  width: 0.25rem;

  @include tablet {
    display: none;
  }
}

.popover {
  position: absolute;
  top: calc(100% + 1.75rem);
  right: 0;
  width: 12.0625rem;
  background: var(--fourth-gradient);
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--secondary-border-color);
  padding: 1.75rem 1.25rem 2.25rem 1.25rem;
  z-index: 9;
  box-shadow: 0 2.5rem 4rem -1.5rem rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;

  @include tablet {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    border: unset;
    background: radial-gradient(
        1969.81% 767.35% at 72.27% 31.43%,
        rgba(255, 80, 26, 0.2) 0%,
        rgba(15, 15, 16, 0.2) 100%
      ),
      linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
  }

  &__mobileHeader {
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    display: none;

    @include tablet {
      display: block;
    }

    & > img {
      width: 112px;
      display: block;
      margin: 0 auto;
    }
  }

  &__mobileClose {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border: none;
    cursor: pointer;
    border-radius: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    color: #ff501a;
    font-size: 2rem;
    line-height: 2rem;
  }

  &__mobileFooter {
    display: none;
    margin-top: auto;

    @include tablet {
      display: block;
    }
  }
}

.refreshBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 0.8rem;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 100%;
  }

  &:hover {
    & > svg path {
      fill: #ff501a;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include tablet {
    margin-right: 14px;
    order: -1;
    width: 16px;
    height: 16px;
  }
}

.depositBtn {
  @include tablet {
    position: absolute;
    right: 0;
    bottom: -2.8rem;
  }
}

.connectBtn {
  background: var(--unnamed-color-7efbef) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-7efbef);
  background: #7EFBEF 0% 0% no-repeat padding-box;
  border: 1px solid #7EFBEF;
  border-radius: 25px;
  font-weight: 600;
  text-shadow: 0px 1px 3px #00000066;

  &_notLogged {
    background: #171717 0% 0% no-repeat padding-box;
    border: 1px solid #7EFBEF;
    border-radius: 21px;
    color: #7EFBEF;
  }
}