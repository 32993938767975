@import '@styles/mixins';

.card {
  width: 381px;
  height: 501px;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border: 1px solid #7EFBEF;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(42px);
  -webkit-backdrop-filter: blur(42px);
  padding: 1.75rem 1.5rem 2rem 1.5rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  @include laptop {
    width: 194px;
    height: 255px;
    padding: 12px;
  }
}

.duration { 
  font-family: "Questrial";
  font-size: 38px;
  text-align: center;
  color: var(--primary-color) !important;
  text-transform: uppercase;

  @include laptop {
    font: normal normal normal 18px/19px Questrial;
  }

}
.contentTitle {
  font-weight: 400;
  font-size: 4rem;
  line-height: 2.25rem;
  text-align: center;
  color: var(--primary-color) !important;
  text-transform: uppercase;
}
.descr {
  margin-top: 10px;
  font-style: normal;
  font-family: "Questrial";
  font-size: 20px;
  text-align: center;
  color: #fff;

  & > span {
    font-weight: 700;
  }

  @include laptop {
    font: normal normal normal 11px/11px Questrial;
  }
}

.rangeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.375rem;
  font-family: "Questrial";
  &_safe {
    margin-top: 55px;
    @include laptop{
      margin-top: 40px;
    }
  }
  & > p {
    font-style: normal;
    font-size: 25px;
    line-height: 1.5rem;
    margin-top: 35px;
    @include laptop {
      font: normal normal normal 11px/11px Questrial;
      margin-top: 17px;
    }
  }

  & span {
    font-style: normal;
    font-size: 20px;
    margin-top: 22px;

  }
  @include laptop {
    margin-bottom: 10px;
  }
}
.selected {
  font-weight: 700;
  font-size: 30px !important;
}
.rangeMarks {
  width: 194px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 41px;
  // margin-left: -0.3rem;
  // margin-right: -0.3rem;

  & > span {
    cursor: pointer;
    padding: 0 0.1rem;
  }
}

.rangeInput {
  margin-top: 0.375rem;
  margin-bottom: 0.75rem;
  align-self: flex-start;
  margin-left: 55px;
  width: 165px;
  height: auto;
}
:global {
  & .rc-slider-rail {
    background: #d2d2d2;
    height: 0.0625rem;
    border-radius: 0.0625rem;
    cursor: pointer;
  }

  & .rc-slider-track {
    background: var(--secondary-gradient);
    border-radius: 0.0625rem;
    height: 0.0625rem;
    cursor: pointer;
  }

  & .rc-slider-handle {
    background: var(--secondary-gradient);
    width: 10px;
    height: 0.5625rem;
    border: 0.125rem solid #758958;
    margin-top: -0.25rem;

    &-dragging {
      box-shadow: none !important;
    }
  }
}

.btn {
  display: flex;
  margin: 0 !important;
  color: #000;
  width: 114px;
  height: 54px;
  /* UI Properties */
  background: #7EFBEF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 29px;
  font-size: 25px;
  font-family: "Questrial";
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  &:hover {
    background: var(--primary-hover-color) !important;
    border: 1px solid var(--primary-hover-border-color) !important;
    margin: 0 !important;
  }

  @include laptop {
    width: 74px;
    height: 32px;
    font-size: 16px;
  }
}

.loaderWrapper {
  font-style: normal;
  font-family: "Questrial";
  font-size: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_safe {
    margin-top: 200px;
    @include laptop {
      margin-top: unset;
    }
  }
  & > span {
    margin-left: 15px;
    margin-bottom: 6px;
    border-color: white;

    & > span {
      background-color:white !important;
    }
  }
  @include laptop {
    font: normal normal normal 11px/11px Questrial;
  }
}

.error {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;

  color: var(--error-color);
}

.freeBtn {
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000066;
  border: 2px solid #7EFBEF;
  border-radius: 27px;
  
  width: 151px;
  height: 53px;

  @include laptop {
    width: 76px;
    height: 26px;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #7EFBEF;
    border-radius: 27px;
    padding: 7px;
    margin-top: 20px;
    font: normal normal normal 11px/11px Questrial;
  }
}
.tokens {
  display: flex;
  gap: 30px;
  margin: auto;
  margin-top: 25px;

  .tokenButton {
      border: 2px solid #7EFBEF;
      width: 66px;
      height: 66px;
      font-size: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 0;
      @include laptop {
        width: 34px;
        height: 34px;
      }
  }
  @include laptop {
    gap: 18px;
    margin-top: 35px;
  }
}
.betContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & .bet {
    font-size: 35px;
    font-family: "Questrial";
    @include laptop {
      font-size: 16px;
      margin-bottom: -5px;
    }
  }

  & .betLabel {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 17px;
    font-family: "Questrial";
    @include laptop {
      font-size: 7px;
      margin-top: 0px;
    }
  }
}