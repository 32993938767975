@import "@styles/mixins";

.content {
    display: block;
    width: 100%;
    padding-left: 114px;
    padding-right: 115px;
    @include tablet {
        display: grid;
    }
    }
    
    .wrapper {
        text-align: center;
        border: 2px solid var(--primary-color);
        border-radius: 15px;
        width: 800px;
        height: 285px;
        border-collapse: collapse;
        display: flow-root;
        padding: 20px;
    }
    .title {
        flex: 1;
        display: block;
        margin: 0 auto;
        text-align: center;
        font-size: 59px;
        text-transform: uppercase;
        color: #7EFBEF;
        @include tablet {
        font-size: x-large;
        }
        
        @include mobile {
            font-size: large;
        }
        
        @include laptop {
            font-size: 28px;
        }
        }

.team {
    //display: flow-root;
    top: 442px;
    left: 114px;
    width: 1691px;
    height: 912px;
    /* UI Properties */
    text-align: center;
    color: var(--primary-color);
    margin: auto;
    margin-top: 166px;
    border: 2px solid var(--primary-color);
    font-size: 38px;
    opacity: 1;
    display: flex;
    & p {
        margin: auto;
    }
    @include laptop {
        
        width: 920px;
        height: 456px;
        border: 1px solid #7EFBEF;
    }
}
.games {
    margin:auto;
    width: 1691px;
    height: 912px;
    color: var(--primary-color);
    display: flex;
    margin-top: 154px;
    border: 2px solid var(--primary-color);
    font-size: 38px;
    margin-bottom: 331.5px;
    grid-template-columns: repeat(2,  1fr);
    gap: 90px;
    & p {
        margin: auto;
    }
    @include tablet {
        grid-template-columns: repeat(1, 1fr);
    }
    @include laptop {
        
        width: 920px;
        height: 456px;
        border: 1px solid #7EFBEF;
    }
}
.teamImage {
    width: 100px;
}
.componentRow {
    flex: 1;
    
}
.componentTitle {
    text-align: center;
    font-size: large;
}
.teamMember {
    margin: auto;
}
.teamMemberName {
    text-align: center;
    margin: auto;
    margin-top: 10px;
}
.comoponentDivider {
    flex: 0.5;
    width: 100%;
    display: flex;
    margin-bottom: 50px;
}

.gameCard {
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
  
  .gameImage {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 23px;
    border: 2px solid var(--primary-color);
  }
  
  .gameContent {
    flex: 1;
    margin-left: 23px;
  }
  
  .gameTitle {
    text-align: left;
    font-size: 35px;
    color: var(--primary-color);
  }
  
  .gameDesc {
    margin-top: 12px;
    text-align: left;
    font-size: 25px;
  }
  