@import "@styles/mixins";

.content {
    display: flex;
    gap: 18px;
    margin: auto;
    }
    
    .wrapper {
        text-align: center;
        width: 100%;
        border-collapse: collapse;
        height: max-content;
        background: #0000004D 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000066;
        border: 2px solid #7EFBEF;
        border-radius: 4px;
        opacity: 1;
        display: flow-root;
        padding: 30px;
        margin-bottom: 10px;
        margin-top: 5px;
        @include laptop {
            padding: 15px;
        }
    }
    .wrapperTitle {
        margin-top: 18px;
        margin-left: 10px;
        text-align: left;
        font-size: large;
        width: 100%;
        font-size: 27px;
        color: var(--primary-color);
        text-transform: uppercase;

        @include laptop {
            font-size: 18px;
        }
    }
    .leftColumn {
        width: 850px;
        height: 742px;
    
        @include tablet {
            flex: 100%;
        }

        @include laptop {
            width: 410px;
            height: 357px;
        }
    }
    
    .rightColumn {
        width: 522px;
        text-align: center;
        cursor: unset;
        @include tablet {
            flex: 100%;
        }
        @include laptop {
            width: 251px;
        }
    }
    
    .poolTitle {
        flex: 1;
        text-align: center;
        display:flow-root;
        margin: auto;
        margin-bottom: 10px;
        text-transform: uppercase;
    
        @include tablet {
            width: 100%;
        }

    }
    
    .tokenlist{
        flex: 1;
        width: 100%;
        display: inherit;
        height: 485px;
        overflow: auto;
        gap: 18px;

        @include laptop {
            height: 233px;
            gap: 5px;
        }
    }
    .additionTokenButton {
        text-align: left;
        flex: 1;
        vertical-align: center;
        font-size: 21px !important;
        font-weight: 100;
        text-transform: uppercase;
        display: flex;
        width: 765px;
        height: 66px;
        margin-top: 15px;
        border-radius: 15px;
        margin-right: 13px;
        border: 1px solid #7EFBEF;
        border-radius: 33px;
        @include laptop {
            width: 369px;
            height: 32px;
            padding: 6px;
            margin-top: 7px;
            margin-right: unset;
        }
    }
    .tokenIcon  {
        width: 44px;
        height: 44px;
        margin-left: 8px;
        border: 2px solid #7EFBEF;
        margin-right: 15px;
        border-radius: 100%;
        @include laptop {
            width: 21px;
            height: 21px;
            margin-top: -1px;
            margin-left: 1px;
        }
    }
    .pickCurrenciesRow {
        flex: 1;
        display: flex;
        margin-left: 8px;
        margin-top: 61px;
        font-size: 27px;
        margin-bottom: 60px;
        text-align: left;
        gap: 225px;
        @include laptop {
            margin-top: 36px;
            gap: 99px;
            margin-bottom: 18px;
        }
    }
    .pickCurrenciesCol {
        font-size: 27px;
        text-align: left;
        display: flex;
        .label {
            width: 300px;
            @include laptop {
                width: 144px;
            }
        }
        @include laptop {
            font-size: 16px;
                margin: auto;
        }
    }
    .currenciesTokenLabel {
        margin: auto;
        text-align: left;
        margin-left: 10px;
        @include laptop {
            font-size: 9px;
        }
    }
    .additionInput{
        background: #000000 0% 0% no-repeat padding-box;
        border: 1px solid #7EFBEE;
        border-radius: 35px;
        font-family: "Questrial";
        font-size: 27px;
        @include laptop {
            font-size: 14px;
            border-radius: 21px;
            width: 116px;
            height: 19px;
        }
    }
    .currency {
        font-style: normal;
        font-size: 21px;
        text-align: right;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        @include laptop {
            font-size: 9px;
            margin-right: 20px;
        }
      }
      .changeLabel {
        font-size: 21px !important;
        height: 100%;
        display: flex;
        align-items: center;

        @include laptop {
            font-size: 9px !important;
        }
    }

    .readyBtn {
        font-size: 35px;
        width: 262px;
        height: 76px;
        font-family: "Questrial";
        margin-top: 50px;
        box-shadow: 0px 3px 6px #00000066;
        border-radius: 38px;
        font-weight: 600;
        &_disabled::after {
            cursor: not-allowed !important;
        }

        @include laptop {
            width: 107px;
            height: 39px;
            font-size: 18px;
            margin-top: 21px;
        }
    }