@import "@styles/mixins";

.wrapper {
  overflow: auto;
  border: 2px solid var(--primary-color);
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  height: 600px;
  background-color: #000000;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  border-radius: 10px;
  &_loading {
    display: grid;
  }
  @include laptop {
    height: 305px;
  }
}
.mainTable{
  border-style: hidden;
  width: 100%;
  font-family: "Questrial";
  border-bottom: 1px solid var(--primary-color);
    &  tr {
      height: 52px;
      border-bottom: 1px solid var(--primary-color);
      & td {
        align-items: center;
        border: 0;
        vertical-align: middle;
        text-align: center;
      }
      & th {
        padding: 8px;
        align-items: center;
        border: 0;
        vertical-align: middle;
        background-color: #0F1D26
      }
      @include laptop {
        height: 30px;
      }
    }
  
   &_light {
    
    background-color: var(--primary-color);
    color: #333333;
    & > th {
      background-color: var(--secondary-color);
      color: white;
    }
    & > td {
      background-color: var(--primary-color);
      border-bottom: 1px solid var(--primary-gradient);
    }
    .columnDivider  {
      border-right: 1px solid #333333;
    }
    .hideCol {
      opacity: 0;
    }
   } 
    
   &_dark {
    
    background-color: var(--primary-gradient);
    color: #ffffff;
    & > td {
      background-color: var(--primary-gradient);
      border-bottom: 1px solid var(--primary-color);
      font-size: 20px;
      @include laptop {
        font-size: 10px;
      }
    }
    & > th {
      background-color: var(--primary-gradient);
      color: white
    }
    .columnDivider {
      border-right: 1px solid var(--primary-color);
    }
    .hideCol {
      opacity: 0;
    }
  }
}
  
  .field {
    text-transform: uppercase;
    color: white;
    font-size: 22px;
    @include laptop {
      font-size: 10px;
    }
  }

  .secondaryRow {
    & td {
      background-color: #0F1D26
      
    }
  }
  .thinRow {
    
    height: 70px !important;
  }

  .mineRow {
    background: #317470 0% 0% no-repeat padding-box !important;
  }
  .footer {
    text-align: center;

    & img {
      width: 144px;
    }
  }