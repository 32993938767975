.loader {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    & > img {
        width: 330px;
        height: 330px;
    }
}