@import "@styles/mixins";

.content {
    display: flex;
    gap: 18px;
    margin: auto;
    }
    
    .wrapper {
        text-align: center;
        border-radius: 10px;
        width: 100%;
        border-collapse: collapse;
        height: max-content;
        background: #0000004D 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000066;
        border: 2px solid #7EFBEF;
        border-radius: 5px;
        opacity: 1;
        padding: 14.75px;
        padding-left: 23.09px;
        display: flow-root;
        &_inner{
            height: 100%;
            padding: 20px;
            position: relative;
            @include laptop {
                padding: 10px;

            }
        }

        &_displayBlock{
            display: block;
        }
        &_withoutHeight{
            height: unset;
        }
    }
    .wrapperTitle {
        margin-top: 18px;
        margin-left: 10px;
        text-align: left;
        font-size: large;
        width: 100%;
        font-size: 27px;
        color: var(--primary-color);
        text-transform: uppercase;
    }
    .leftColumn {
        width: 850px;
        height: 742px;
    
        @include tablet {
            flex: 100%;
        }
    }
    
    .rightColumn {
        width: 522px;
        text-align: center;
        cursor: unset;
        @include tablet {
            flex: 100%;
        }
    }
    
    .poolTitle {
        flex: 1;
        text-align: center;
        display:flow-root;
        margin: auto;
        margin-bottom: 10px;
        text-transform: uppercase;
    
        @include tablet {
            width: 100%;
        }

    }
    
    .tokenlist{
        flex: 1;
        width: 100%;
        display: inherit;
        height: 485px;
        overflow: auto;
        gap: 18px;
    }
    .additionTokenButton {
        text-align: left;
        flex: 1;
        vertical-align: center;
        font-size: 21px !important;
        font-weight: 100;
        text-transform: uppercase;
        display: flex;
        width: 765px;
        height: 66px;
        margin-top: 15px;
        border-radius: 15px;
        margin-right: 13px;
        
    }
    .tokenIcon  {
        width: 44px;
        height: 44px;
        margin-left: 8px;
        border: 2px solid #7EFBEF;
        margin-right: 15px;
        border-radius: 100%;
    }
    .pickCurrenciesRow {
        flex: 1;
        display: flex;
        margin-left: 8px;
        margin-top: 61px;
        font-size: 27px;
        margin-bottom: 60px;
        text-align: left;
        gap: 225px;
    }
    .pickCurrenciesCol {
        font-size: 27px;
        text-align: left;
        
        .label {
            width: 300px;
        }
    }
    .currenciesTokenLabel {
        margin: auto;
        text-align: left;
        margin-left: 10px;
    }
    .additionInput{
        border: 0;
        border-bottom: 1px solid var(--primary-color);
        background: transparent;
        font-family: "Questrial";
        font-size: 27px;
        border-radius: 0px;
    }
    .currency {
        font-style: normal;
        font-size: 21px;
        text-align: right;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
      }
      .changeLabel {
        font-size: 21px !important;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .readyBtn {
        font-size: 35px;
        width: 262px;
        height: 76px;
        font-family: "Questrial";
        margin-top: 50px;
        box-shadow: 0px 3px 6px #00000066;
        border-radius: 38px;

        &_disabled::after {
            cursor: not-allowed !important;
        }
    }
