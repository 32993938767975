@import "@styles/mixins";

.portfolio {
    width: 100%;
    flex:  1;

    @include laptop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_game {
            height: 178px;
        }
    }
}
.portfolioTitle {
    text-align: left;
    text-transform: capitalize;
    display: flex;
    &_game {
        font-size: 13px;
        margin-bottom: 13px;
    }

    & > div {
        display: contents;
        width: min-content;
        margin: auto;
        text-align: start;
        align-items: start;
        justify-content: start;
        text-transform: capitalize;
    }
}
.tokenRower {
    display: flex;
    border-bottom: 2px solid #7EFBEF;
    padding-bottom: 6px;
    height: 130px;
    @include laptop{
        height: 75px;
    }
}
.tokenBox {
    display: inline-block;
    margin: 5px;
    margin-right: 10px;
    width: 77px;
    height: 77px;
    text-align: center;

    @include laptop {
        width: 37px;
        height: 57px;
        margin-right: unset;
    }
}

.remover {
    &:hover {
        border-radius: 0px;
        .closeBtn {
            display: block;
        }
    }
}
.tokenIcon {
    margin: auto;
    padding: 5px;
    width: 77px;
    height: 77px;
    border: 1px solid #7EFBEF;
    border-radius: 9px;
    opacity: 1;

    @include laptop {
        width: 37px;
        height: 37px;
        border-radius: 100%;
        padding: 1px;
    }
    .closeBtn {
        display: none;
        margin-left: -5px;
        margin-top: 0px;
        text-align: end;
        position: absolute;
        color: #7EFBEF;
        width: 65px;
        text-transform: lowercase;
        font-size: 20px;

        @include laptop {
            width: 35px;
            font-size: 15px;
        }
    
    }

    &:hover {
        color: red;
        cursor: pointer;

        .closeBtn {
            color: red;
        }
    }

    img{
        border-radius: 50%;
    }
}
.tokenTotalLabel {
    text-align: right;
    font-size: 25px;
    margin-top: 9px;
    text-transform: capitalize;
    &_preparation {
        color: white;
    }
    @include laptop {
        font-size: 9px;
        margin-top: 5px;
    }
}
.colorRed {
    color: red;
}
.colorGreen {
    color: green;
}
.change {
    margin-top: 15px;
    font-size: 24px;

    @include laptop {
        margin-top: 10px;
        font-size: 9px;
    }
}
.emptyToken {
    display: hide;
}
