@import "@styles/mixins";

.search {
  padding-bottom: 3rem;
  margin-top: -50px;
  @include tablet {
    padding-bottom: 42px;
    margin-top: 0;
  }
}

.title {
  display: grid;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  font-family: "Questrial";
  font-size: 51px;
  text-transform: uppercase;
  @include tablet {
    font-size: x-large;
  }
  @include mobile {
    font-size: large;
  }
  
  @include laptop {
    font-size: 28px;
  }
}

.titleButtons {
  margin: auto;
  margin-top: 73px;
  gap: 64px;
  display: flex;
  .btn {
    font-size: 30px;
    height: 68px;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #7EFBEF;
    border-radius: 100px;
    color: white;
    font-weight: 600;
    &_selected {
      background: #7EFBEF 0% 0% no-repeat padding-box;
      border: 1px solid #7EFBEF;
      border-radius: 100px;
      color: black;
    }
    @include laptop {
      height: unset;
      font-size: 14px;
      padding: 10px 14px;
    }

  }
  @include laptop {
    margin-top: 40px;
    gap: 40px;
  }
}
.cardsWrapper {
  margin-top: 89px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 2.875rem;
  justify-content: center;

  @include tablet {
    display: none;
  }
}
.contentBoard {
  margin-top: 89px;
  display: grid;
  justify-content: center;
}
.mobileSlider {
  display: none;
  margin-top: 36px;

  & h3 {
    font-weight: 700;
    font-size: medium;
    text-align: center;
  }

  @include tablet {
    display: block;
  }
}

:global {
  .swiper {
    margin-top: 12px;
  }

  .swiper-pagination-bullets {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 20px;
    justify-content: center;
    top: 0;
    bottom: unset !important; 
  }

  .swiper-wrapper {
    margin-top: 66px;
  }
}

.mobileBullet {
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--primary-hover-border-color);
  cursor: pointer;

  &_active {
    opacity: 1;
    background: var(--primary-gradient);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    margin-top: 0.75rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
