@import "@styles/mixins";
.mainContent {
    display: flex;
    text-align: center;
}
.content {
    display: flex;
    margin: auto;
    gap:17.89px;
    }
    .wrapper {
        text-align: center;
        width: 100%;
        border-collapse: collapse;
        height: max-content;
        background: #0000004D;
        border: 2px solid #36FFF0;
        border-radius: 5px;
        display: flow-root;

        &_inner{
            height: 100%;
            padding: 20px;
            position: relative;
            display: grid;
            @include laptop {
                padding: 7px;
            }
        }
        &_displayBlock{
            display: block;
        }
        &_withoutHeight{
            height: unset;
        }
        &_graph {
            margin-top: 0px;
        }

        &_hideLaptop {
            @include laptop {
                width: 0px;
                height: 0px;
                visibility: hidden;
                display: none;
    
            }
        }
      }
    .safeWrapper {
        border: 0;
        background: none;
        gap: 18px;
        padding: 0px;

        @include laptop {
            gap: 6px;
        }
    }
    .portfolio {
        width: 100%;
        flex:  1;
        margin-top: 10px;
    }
    .portfolioTitle {
        text-align: left;
    }
    .tokenRower {
        display: flex;
    }
    .tokenBox {
        display: inline-block;
        margin: 5px;
        width: 50px;
        height: 50px;
        text-align: center;
    }
    .tokenIcon {
        margin: auto;
        padding: 5px;
        width: 50px;
        border-radius: 10px;
        border: 2px solid var(--primary-color);
    }
    .tokenTotalLabel {
        text-align: right;
        text-transform: capitalize;
    }
    .colorRed {
        color: red;
    }
    .colorGreen {
        color: green;
    }
    .chatWrapper {
        height: 100%;
        display: grid;
    }
    .muteWrapper {
        width: 100%;
        text-align: left;
        margin-top: -5px;
        padding-bottom: 5px;
        font-size: 20px;
        border-bottom: 2px solid #7EFBEF;

        @include laptop {
            margin-top: 0px;
            font-size: 15px;
        }
    }
    .chatMute {
        width: 76.57px;
        height: 28px;
        align-self: flex-end;
        text-align: center;
        padding: 5px;
        font-size: 16px;
        font-family:"Questrial" ;
        margin-top: -5px;
        float: right;
        background: #FF367C 0% 0% no-repeat padding-box;
        border: 1px solid #FF367C;
        border-radius: 15px;
        color: white;

        &_muted {
            background-color: black;
            color: #FF367C;
        }
        @include laptop {
            width: 59px;
            height: 18px;
            font-size: 11px;
            padding: 0px;
        }
    }
    .chatScreen {
        height: 190px;
        overflow: auto;
    }
    
    .chatMessage {
        color: white;
        text-align: left;
        display: flex;
        margin-top: 5px;
    }
    .chatMessage:last-child {
        // Scroll to the bottom when a new message is added
        animation: scrollAnimation 0.1s forwards;
      }

    @keyframes scrollAnimation {
        to {
          scroll-behavior: smooth;
          scroll-snap-align: end;
        }
      }
    hr {
        color: var(--primary-color);
    }
    
    .chatMessageSender {
        text-transform: uppercase;
        margin-right: 5px;

        @include laptop {
            font-size: 9px;
        }
    }
    .sendMessage {
        margin-top: 10px;
        display: flex;
    }
    .form {
        padding-top: 0.75rem;
        display: contents;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: relative;
        & > form > input {
            width: 80%;
        }
        
      }
    .title {
        display: block;
        margin: 0 auto;
        text-align: center;
        font-size: xx-large;
        text-transform: uppercase;
        @include tablet {
        font-size: x-large;
        }
        
        @include mobile {
            font-size: large;
        }
        }
        
        .leftColumn {
            width: 498.17px;
            min-height: 750px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            @include tablet {
                flex: 100%;
                width: 247px;
            }
            @include laptop {
                width: 247px;
                min-height: 380px;
            }

        }
        
        .rightColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 498px;
            min-height: 750px;
            @include tablet {
                flex: 100%;
                width: 247px;
            }
            @include laptop {
                width: 247px;
                min-height: 380px;
            }
        }
        
        .midColumn {
            width: 828px;
            min-height: 750px;
            @include tablet {
                width: 411px;
                flex: 100%;
            }
            @include laptop {
                width: 411px;
                min-height: 380px;
            }
        }
        .poolTitle {
            width: 100%;
            text-align: center;
            display:flex;
            justify-content: space-between;
            text-transform: uppercase;
        
            @include tablet {
                width: 100%;
            }

        }
        .prizePoolTitle {
            text-align: center;
            font-size: 27px;
            color: var(--primary-color);
            text-transform: uppercase;
            display: flex;
            align-items: center;
            .bet {
                font-weight: 700;
                width: 174px;
                height: 77px;
                /* UI Properties */
                background: #7EFBEF 0% 0% no-repeat padding-box;
                border: 2px solid #7EFBEF;
                border-radius: 4px;
                opacity: 1;
                font-size: 30px;
                font-family: "Questrial";
                color: black;
                text-align: center;
                display: grid;
                align-items: center;
                line-height: 1.5px;
                @include laptop {
                    margin-left: 2px;
                    width: 70px;
                    height: 38px;
                    font-size: 13px;
                }
                & img {
                    width: 48px;
                    height: 41px;
                }
                &_game {
                    margin-left: 0;
                    width: 240px;
                    height: 93px;
                    @include laptop{
                        width: 142px;
                        height: 76px;
                    }
                }
                
                .prize {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            @include laptop {
                font-size: 15px;
            }
        }
    .withMusicWrapper {
        display: flex;
    }
    .loader {
        width: 100%;
        height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        & > img {
            width: 330px;
            height: 330px;
        }
    }
    .sendMessageBtn {
        padding: 5px 7px;
        & > img {
            width: 30px;
        }

        
    }
    .lockedChat {
        width: 100%;
        height: 100%;
        opacity: 1;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
        & img {
            width: 54px;
            height: 54px;
        }

        @include laptop {
            width: 100%;
            height: 100%;
        }
    }

  .checkLaptop {
    &_visibleLaptop {
        @include desktop {
            visibility:hidden;
            width: 0px;
            height: 0px;
            display: none;

        }
        @include laptop {
            visibility:visible;
            width: unset;
            height: unset;
            display: contents;
        }
    }
    &_visibleDesktop {
        @include desktop {
            visibility:visible;
            width: unset;
            height: 618px;

        }
        @include laptop {
            visibility:hidden;
            width: 0px;
            height: 0px;

        }
    }
  }
