@import '../../../styles/mixins';

.button {
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 1.5rem;
  font-style: normal;
  font-weight: normal;
  border: 0.1px solid;
  border-color: var(--primary-gradient);
  color: var(--primary-text-color);
  font-family: 'Questrial', sans-serif;
  text-shadow: 0 1px 3px #00000066;
  letter-spacing: 0;
  line-height: 20px;

  text-transform: uppercase;
  @include tablet {
    font-size: 14px;
    line-height: 20px;
  }

  //&:hover {
  //  background: var(--primary-hover-color);
  //  border: 1px solid var(--primary-hover-border-color);
  //}

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &_secondary {
    border: 2px solid var(--primary-color);
    background:  var(--primary-gradient);
    color: var(--primary-color);

    //&:hover {
    //  border-color: var(--secondary-color);
    //  background-color: black;
    //}
  }

  &_third {
    background: var(--primary-gradient);
    border-color: var(--negative-color);
    color: var(--negative-color);

    //&:hover {
    //  border-color: var(--secondary-color);
    //  color: var(--secondary-color);
    //  background-color: black;
    //}
  }

  &_small {
    font-size: 20px;
    padding: 11px 12px;

    //@include tablet {
    //  padding: 0.3rem 0.5rem;
    //}
  }

  &_medium {
    padding: 0.86rem 1.1rem;

    //@include tablet {
    //  padding: 0.3rem 0.5rem;
    //}
  }

  &_large {
    font-size: 32px;
    border-radius: 61px;
    padding: 35px 35.5px;

    //@include tablet {
    //  padding: 0.3rem 0.5rem;
    //}
  }

  &_xlarge {
    font-size: 36px;
    border-radius: 61px;
    padding: 39px 69px;

    //@include tablet {
    //  padding: 0.3rem 0.5rem;
    //}
  }
}
