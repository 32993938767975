@import "@styles/mixins";

.wrapper {
    margin-top: 174px;
    text-align: center;
}
.content {
    display: inline-block;
    padding: 50px;
    width: 1322px;
    @include tablet {
        display: grid;
    }
    @include laptop {
        width: 648px;
    }
    }
    .title {
        display: grid;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        font-size: xx-large;
        width: 500px;
        height: 80px;
        text-transform: uppercase;
        font-weight: bolder;
        color: var(--primary-color);
        font-family: "Questrial";
        @include laptop {
          width: 274px;
          height: 35px;
        }
        @include tablet {
          font-size: x-large;
        }
        @include mobile {
          font-size: large;
        }

    }      