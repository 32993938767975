@import-normalize;
@import 'reset-css';

@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'rc-slider/assets/index.css';

@import './mixins';

@import './constants.scss';
@import './fonts.scss';
@import './titles.scss';
@import './inputs.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;}

*::-webkit-scrollbar-track {
  background: var(--primary-color);
}

*::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 2px solid black;
  height: 5px;
  width: 2px;
}

html {
  font-size: 16px;

  @media (max-width: 1441px) {
    font-size: 1.08vw;
  }

  @include tablet {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-size: 1rem;
  color: #fff;
}

img {
  width: 100%;
}

.container {
  margin: 0 auto;
  padding: 0 4rem;

  @include tablet {
    padding: 0 15px;
  }
}

.global-bg {
  background-image: url('../assets/images/Background.png');
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    //background-image: url('../assets/images/global-bg.png');
  }
}

.bg{
  background-image: url('../assets/images/Background-game.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
