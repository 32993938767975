//--------DEVICE SIZE--------
$mobile-width: 375px;
$tablet-width: 768px;
$laptop-width: 1200px;
$desktop-width: 1500px;

@mixin mobile-width {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$laptop-width - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$laptop-width + 1px}) {
    @content;
  }
}


@mixin gradientText {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin secondaryGradientText {
  background: var(--secondary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
