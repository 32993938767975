.input {
  background: black;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  padding: 0.75rem;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
  border: 0.0625rem solid var(--secondary-color);

  &:focus {
    outline: none;
  }

  &_error {
    border-color: var(--error-color);
  }
}
