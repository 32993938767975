@import "@styles/mixins";



.darkParagraph {
    margin-bottom: 55px;
    & h3 {
        color: var(--primary-color);
    }
    & p {
        color: white;
    }
    @include tablet {
        margin-bottom: 20px;
    }
}
.lightParagraph {
    margin-bottom: 55px;
    & h3 {
        color: var(--primary-color);
    }
    & p {
        color: var(--primary-gradient);
    }
    @include tablet {
        margin-bottom: 20px;
    }
}

.title {
    cursor: pointer;
    left: 183px;
    height: 39px;
    text-align: left;
    font: normal normal normal 38px Questrial;
    letter-spacing: 0px;
    color: #7EFBEF;
    opacity: 1;
    display: flex;

    .label {
        margin-left: 51px;
        @include tablet {
            font-size: 25px;
            margin-left: 25px;
        }
        @include laptop {
            font-size: 25px;
        }
    }
    & svg {
        width: 20px;
        height: 30px;
        & path {
            d: path("M 0 0 L 20 15 L 0 30 Z");
        }
    }
}

.paragraph {
    margin-top: 30px;
    margin-left: 86px;
    font-size: 38px;
    top: 422px;
    left: 183px;
    text-align: left;
    font: normal normal normal 38px Questrial;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    @include tablet {
        margin-top: 10px;
        font-size: 20px;
        margin-left: 45px;
    }
    @include laptop{
        margin-left: 72px;
        font-size: 25px;
    }
}