@import "@styles/mixins";
.content {
    width: 100%;
    @include tablet {
        width: 98%;
    }
    }

.title {
    display: block;
    font-family: "Questrial";
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    color: #7EFBEF;
    @include tablet {
      font-size: 25px;
      margin-top: 20px;
    }
    @include laptop {
        font-size: 28px;
    }
}  

.form {
    padding-top: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    margin: auto;
    margin-top: 63px;
    width: 1038px;
    
    @include tablet {
        width: 100%;
        margin-left: 10px;
      }
  }
.midColumn {
    display: flex;
    gap: 18px;
    & input {
        background: #00000067 0% 0% no-repeat padding-box;
        width: 510px;
        height: 104px;
        font-size: 18px;
        padding: 23px;
        border: 2px solid var(--primary-color);
        border-radius: 15px;
        text-transform: uppercase;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
          }

        @include tablet {
            width: 100%;
        }
        @include laptop {
            
            width: 305px;
            height: 62px;
            /* UI Properties */
            background: #05141D 0% 0% no-repeat padding-box;
            border: 2px solid #7EFBEF;
            border-radius: 5px;
        }
    }
        
    @include tablet {
        display: grid;
    }
}
.fullCollumn {
    margin-top: 18px;
    flex: 1;
    width: 100%;
    & > textarea {
        height: 321px;
        font-size: 18px;
        border: 2px solid var(--primary-color);
        border-radius: 15px;
        background: #00000067 0% 0% no-repeat padding-box;
        padding: 23px;
        
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
          }

        @include laptop {
        width: 622px;
        height: 193px;
        /* UI Properties */
        background: #05141D 0% 0% no-repeat padding-box;
        border: 2px solid #7EFBEF;
        border-radius: 5px
        }

    }
    max-width: 100%;
    text-align: center;
}
.btn {
    margin-top: 5px;
    width: 169px;
    height: 63px;
    border-radius: 37px;
    font-size: 38px;
    border: 0px;
    margin-bottom: 202.5px;
    font-weight: 600;
    @include laptop {
        font-size: 22px;
        width: 101px;
        height: 44px;
        
    }
}