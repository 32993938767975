@import "@styles/mixins";

.wrapper  {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    width: 536px;
    flex-direction: column;
    margin: auto;
    .title {
        color: #7EFBEF;
        height: 45px;
        font-size: 30px;
        font-weight: 500;
        &_second {
            color: #FF6026;
        }

        @include laptop {
            font-size: 18px;
            height: 20px;
        }
    }

    .content {
        background: #161923 0% 0% no-repeat padding-box;
        border: 2px solid #7EFBEF;
        border-radius: 5px;
        width: 100%;
        &_second {
            border-color: #FF6026;
        }

        & .row {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 135px;
            flex-direction: column;
            border-bottom: 2px solid #7EFBEF;
            & .stars {
                margin-left: 20px;
                display: flex;
                gap: 5px;

                & img {
                    width: 23px;

                    @include laptop {
                        width: 15px;
                    }
                }

                @include laptop {
                    margin-left: 0px;
                    gap: 3px;
                }
            }

            & .liner {
                margin-left: -20px;
                margin-top: 8px;
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                & .bar {
                    width: 206px;
                    height: 12px;
                    background: transparent linear-gradient(90deg, #7EFBEF 0%, #00FFE1 100%, #3B5A57 100%) 0% 0% no-repeat padding-box;
                    border-radius: 5px;

                    &_second {
                        background:  transparent linear-gradient(90deg, #FF6026 0%, #FF6026 100%, #3B5A57 100%) 0% 0% no-repeat padding-box;
                    }

                    @include laptop {
                        width: 128px;
                        height: 7px;
                        border-radius: 15px;
                    }
                }
                & > .info {
                    width: 36px;
                    height: 36px;
                    color: #7EFBEF;

                    @include laptop {
                        width: 23px;
                        height: 22px;
                    }
                }

                @include laptop {
                    gap: 3px;
                    margin-top: 0px;
                }
            }

            & .values {
                margin-left: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 30px;

                @include laptop {
                    margin-left: 0px;
                    font-size: 19px;
                }
            }

            & .subTitle {
                color: #7EFBEF;
                font-size: 30px;
                text-align: center;
                font-weight: 500;
                border-bottom: 2px solid #7EFBEE;
                padding-left: 20px;
                padding-right: 20px;
                &_second {
                    color: #FF6026;
                    border-bottom: 2px solid #FF6026;
                }

                @include laptop {
                    font-size: 18px;
                }
            }

            & .subData {
                border: 2px solid #7EFBEE;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                padding: 10px;
                margin-top: -2px;
                &_second {
                    border: 2px solid #FF6026;
                }

                @include laptop {
                    font-size: 18px;
                    height: 30px;
                }
            }


            &_firstSecond {
                border-bottom: 3px solid #FF6026;
            }

            @include laptop {
                height: 83px;
            }
        }

    }

    @include laptop {
        width: 331px;
    }
}
.nonBottom {
    border-bottom: 0px !important;
}