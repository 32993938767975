@import "@styles/mixins";

.container {
    background-color: transparent;
    height: calc(100vh - 82px);
    position: absolute;
    top: 82px;
    right: 0px;
    margin: auto;
    margin-right: 0px;
    width: 72px;
    display: flex;
    z-index: 10;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
    .triggerArea {
        height: 100%;
        display: flex;
        align-items: center;
        width: 72px;

        @include laptop {
            width: 35px;
        }
    }
    .trigger {
        width: 72px;
        background: #7EFBEF 0% 0% no-repeat padding-box;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 138px;
        cursor: pointer;

        & img {
            width: 36px;
            height: 36px;
        }

        @include laptop {
            width: 35px;
        }
    }


    .sliderContent {
        display: none;
        align-items: center;
        flex-direction: column;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;

        &_open {
            display: flex;
            gap: 50px;
            align-items: center;
            justify-content: center;
        }
    }

    @include laptop {
        width: 35px;
    }
    &_opened {
        width: 600px;
        border-left: 30px solid #7EFBEF;
        background-color: black;
        .trigger {
            width: 72px;
            margin-left: -30px;

            @include laptop {
                width: 35px;
                margin-left: -15px;
            }
        }
        .triggerArea {
            width: 32px;
        }

        @include laptop {
            width: 310px; // change it later
            border-left: 15px solid #7EFBEF;
        }
    }
}