@import "@styles/mixins";

.wrapper {
  margin-top: 148px;
  margin-left: 150px;
  margin-right: 150px;

  .title {
    display: grid;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    font-size: xx-large;
    text-transform: uppercase;
    font-weight: bolder;
    width: 590px;
    height: 80px;
    color: var(--primary-color);
    font-family: "Questrial";
    @include tablet {
      font-size: x-large;
    }
    @include mobile {
      font-size: large;
    }
    @include laptop {
      width: 350px;
    }
  }
  .imageVideoWrapper{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    & .imageWrapper {
      width: 401px;
      height: 644px;
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      & > img {
        width: unset;
        @include laptop {
          max-height: 154px;
        }
      }
      @include laptop {
        height: unset;
      }
    }

    & .videoWrapper {
      width: 1152px;
      height: 654px; 
      background: #000000 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000066;
      border: 2px solid #7EFBEF;
      border-radius: 33px;
      & iframe {
        width: 100%;
        height: 100%;
        border-radius: 33px;
      }

      @include laptop {
        width: 630px;
        height: 358px;
      }
    }
  }

  .textWrapper {
    margin-top: 47px;
    align-items: center;
    justify-content: space-between;
    padding-left: 3.125rem;
    padding-right: 3.8rem;
    display: flex;
    justify-content: center;
    font-family: 'Questrial';

    @include tablet {
      display: none;
    }

    & h4 {
      font-size: 20px;
      text-align: center;
      height: 52px;
      background: #000000 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000066;
      border: 2px solid #7EFBEF;
      border-radius: 35px;
      justify-content: center;
      display: flex;
      padding: 14px;
      align-items: center;
      @include laptop {
        font-size: 11px;
        padding: pxpx;
        height: 28px;
      }
    }
  }

  .textDivider {
    width: 6rem;
    height: 0.125rem;
    background: var(--secondary-gradient);
    border-radius: 0.0625rem;
  }

  .textMultiple {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 26rem;

    @include tablet {
      width: 100%;
    }

    & > span {
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 0.25rem;
      background: var(--secondary-gradient);

      @include tablet {
        margin-top: 12px;
      }
    }
  }

}
