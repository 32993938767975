@import "@styles/mixins";
.mainContent {
    display: flex;
    text-align: center;
}
.content {
    display: flex;
    margin: auto;
    gap:17.89px;
    }
    .wrapper {
        text-align: center;
        width: 100%;
        border-collapse: collapse;
        height: max-content;
        background: #0000004D;
        border: 2px solid #36FFF0;
        border-radius: 5px;
        display: flow-root;

        &_inner{
            height: 100%;
            padding: 20px;
            position: relative;
            display: grid;
            @include laptop {
                padding: 7px;
            }
        }
        &_displayBlock{
            display: block;
        }
        &_withoutHeight{
            height: unset;
        }
        
        &_graph {
            margin-top: 0px;
        }
        &_hideLaptop {
            @include laptop {
                width: 0px;
                height: 0px;
                visibility: hidden;
                display: none;
            }
        }
        
      }
    .safeWrapper {
        border: 0;
        background: none;
        gap: 18px;
        padding: 0px;
    }
    .portfolio {
        width: 100%;
        flex:  1;
        margin-top: 10px;
    }
    .portfolioTitle {
        text-align: left;
    }
    .tokenRower {
        display: flex;
    }
    .tokenBox {
        display: inline-block;
        margin: 5px;
        width: 50px;
        height: 50px;
        text-align: center;
    }
    .tokenIcon {
        margin: auto;
        padding: 5px;
        width: 50px;
        border-radius: 10px;
        border: 2px solid var(--primary-color);
    }
    .tokenTotalLabel {
        text-align: right;
        text-transform: capitalize;
    }
    .colorRed {
        color: red;
    }
    .colorGreen {
        color: green;
    }
    .chatWrapper {
        height: 100%;
        display: grid;

        &_map {
            width: 100%;
            border: 2px solid #7EFBEF;
            border-radius: 15px;
            padding: 10px;
        }
    }
    .muteWrapper {
        width: 100%;
        text-align: left;
        margin-top: -5px;
        padding-bottom: 5px;
        font-size: 20px;
        border-bottom: 2px solid #7EFBEF;
        &_map {
            display: flex;
            justify-content: end;
            margin-top: 10px;
            margin-right: 10px;
        }

        @include laptop {
            margin-top: 0px;
            font-size: 15px;
        }
    }
    .chatMute {
        width: 76.57px;
        height: 28px;
        align-self: flex-end;
        text-align: center;
        padding: 5px;
        font-size: 16px;
        font-family:"Questrial" ;
        margin-top: -5px;
        float: right;
        background: #FF367C 0% 0% no-repeat padding-box;
        border: 1px solid #FF367C;
        border-radius: 15px;
        color: white;

        &_muted {
            background-color: black;
            color: #FF367C;
        }
    }
    .chatScreen {
        height: 190px;
        overflow: auto;
    }
    
    .chatMessage {
        color: white;
        text-align: left;
        display: flex;
        margin-top: 5px;
    }
    .chatMessage:last-child {
        // Scroll to the bottom when a new message is added
        animation: scrollAnimation 0.1s forwards;
      }

    @keyframes scrollAnimation {
        to {
          scroll-behavior: smooth;
          scroll-snap-align: end;
        }
      }
    hr {
        color: var(--primary-color);
    }
    
    .chatMessageSender {
        text-transform: uppercase;
        margin-right: 5px;

        @include laptop {
            font-size: 9px;
        }
    }
    .sendMessage {
        margin-top: 10px;
        display: flex;
    }
    .form {
        padding-top: 0.75rem;
        display: contents;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: relative;
        & > form > input {
            width: 80%;
            background-color: black !important;
        }
        
      }
    .title {
        display: block;
        margin: 0 auto;
        text-align: center;
        font-size: xx-large;
        text-transform: uppercase;
        @include tablet {
        font-size: x-large;
        }
        
        @include mobile {
            font-size: large;
        }
        }
        
        .leftColumn {
            width: 498.17px;
            min-height: 750px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include laptop {
                width: 247px;
            }
            
            @include tablet {
                flex: 100%;
                min-height: 380px;
                width: 247px;
            }
        }
        
        .rightColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 498px;
            min-height: 750px;
            @include laptop {
                width: 247px;
                min-height: 380px;
            }
            @include tablet {
                flex: 100%;
                width: 247px;
            }
        }
        
        .midColumn {
            width: 828px;
            min-height: 750px;
            @include laptop {
                width: 411px;
                height: 380px;
                min-height: unset;
            }
            @include tablet {
                flex: 100%;
                width: 411px;
            }
        }
        .poolTitle {
            flex: 1;
            width: 100%;
            text-align: center;
            display:flex;
            justify-content: space-between;
            text-transform: uppercase;
        
            @include tablet {
                width: 100%;
            }

        }
        .prizePoolTitle {
            text-align: center;
            font-size: 27px;
            color: var(--primary-color);
            text-transform: uppercase;
            display: flex;
            align-items: center;
            .bet {
                font-weight: 700;
                margin-left: 7px;
                width: 174px;
                height: 77px;
                /* UI Properties */
                background: #7EFBEF 0% 0% no-repeat padding-box;
                border: 2px solid #7EFBEF;
                border-radius: 15px;
                opacity: 1;
                font-size: 30px;
                font-family: "Questrial";
                color: black;
                text-align: center;
                display: grid;
                align-items: center;
                align-content: center;
                line-height: 1.5px;
                @include laptop {
                    margin-left: 2px;
                    width: 70px;
                    height: 38px;
                    font-size: 18px;
                }
                & img {
                    width: 48px;
                    height: 41px;
                }
                &_game {
                    margin-left: 0;
                    width: 240px;
                    height: 93px;
                    @include laptop{
                        width: 142px;
                        height: 76px;
                    }
                }
                
                .prize {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    .withMusicWrapper {
        display: flex;
    }
    .loader {
        width: 100%;
        height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        & > img {
            width: 330px;
            height: 330px;
        }
    }
    .sendMessageBtn {
        padding: 5px 7px;
        & > img {
            width: 30px;
        }
    }
    .lockedChat {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        display: flex;
        font-size: 40px;
        justify-content: center;
        text-align: center;
        align-items: center;
        color: white;
        background-color: #00000049;

        & img {
            width: 60px;
        }

        @include laptop {
            width: 100%;
            height: 100%;
        }
    }

    .infoWrapper {
        margin: auto;  
        margin-bottom: 50px;
        margin-top: -160px;
        width: 165px;
        font-size: 20px;  
        text-align: -webkit-center;
        & .info {
            margin-bottom: 30px;
            color: white;
            text-transform: uppercase;
        }
        & .timer {
            margin-bottom: 50px;
            background-color: var(--primary-color);
            border-radius: 15px;
            font-size: 45px;
            font-weight: 600;
            color: black;
            height: 50px;
            width: 70%;
            display: flex;
            justify-content: center;
        }
    }

    .checkLaptop {
        &_visibleLaptop {
            @include desktop {
                visibility:hidden;
                width: 0px;
                height: 0px;
                display: none;
    
            }
            @include laptop {
                visibility:visible;
            width: unset;
            height: unset;
    
            }
        }
        &_visibleDesktop {
            @include desktop {
                visibility:visible;
                width: unset;
                height: 618px;
    
            }
            @include laptop {
                visibility:hidden;
                width: 0px;
                height: 0px;
    
            }
        }
      }
