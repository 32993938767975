@import "./mixins";

.groovyTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: 0.0625rem;
  color: var(--primary-text-color);
  text-align: center;

  @include tablet {
    font-size: 24px;
    line-height: 32px;
  }

  &_sub {
    font-size: 1.75rem;
    line-height: 2.25rem;

    @include tablet {
      font-size: 28px;
      line-height: 36px;
    }
  }
}
