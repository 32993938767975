@import "@styles/mixins";

.loader {
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    & > img {
        width: 330px;
        height: 330px;
    }
}
.mainContent {
    align-items: center;
    text-align:center;
    justify-content:center;
    margin: auto;
    width: 588px;

    & .title {
        text-align: left;
        font: normal normal normal 50px Questrial;
        letter-spacing: 0px;
        color: #FFFFFF;
        justify-content: center;
        display: flex;
        margin-bottom: 35px;
        text-transform: uppercase;

        @include laptop {
            font-size: 38px;
            margin-bottom: 31px;
        }
    }
    & .content {
        background: #000000 0% 0% no-repeat padding-box;
        border: 2px solid #7EFBEF;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & .contentRow {
            flex-direction:row;
            display: flex;
            gap: 3px;

            &_buttons {
                flex-direction: row;
            }
        }

        @include laptop {
            width: 321px;
            margin: auto;
        }
    }

    @include laptop {
        width: 421px;
    }
}

.joinBtn {
    margin-top: 20px;
    width: 230px;
    height: 80px;
    /* UI Properties */
    background: #7EFBEE 0% 0% no-repeat padding-box;
    border-radius: 51px;
    font: 'Questrial';
    letter-spacing: 0px;
    color: #171717;;
    border-radius: 51px;
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 500;
    &_second {
        margin-left: 65px;
        background: #FF367C 0% 0% no-repeat padding-box;
    }
    margin-bottom: 10px;

    @include laptop {
        width: 116px;
        height: 43px;
        font-size: 22px;

        &_second {
            margin-left: 30px;
        }
    }
}

.table {
    height: unset;
    font-size: 28px;
    overflow: hidden;
    & table {
    & > thead {
        & > tr {
        height: 81px !important;
        & > th {
            
        font-size: 29px !important;
        color: white !important;
        }
        }
    }
}
}