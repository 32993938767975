@import "@styles/mixins";

.footer {
  height: 23px;
  /*background: radial-gradient(50% 392% at 73.1% 82%, rgba(92, 56, 238, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected ,
    linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);*/
  display: table;
  align-items:end;
  justify-content: space-between;
  margin-bottom: 29px;
  margin-top: 50px;
  
  @include tablet {
    background: unset;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding-top: 20px;
    margin-bottom: 50px;
    align-items: center;
    display: inherit;
  }
}

.logo {
  width: 3.75rem;

  @include tablet {
    display: none;
  }
}

.copyright {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;

  @include tablet {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.social {
  display: flex;
  gap:20px;
  float: right;
  grid-template-columns: repeat(2, 1fr);
  margin-right: 102px;
  @include tablet {
    margin-top: 12px;
    margin-right: 0px;
  }

  & a {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
  } 
  & img {
    width: 20.77px;
    height: 20.77px;
  }
}

.homeIndicator {
  display: none;
  width: 134px;
  height: 5px;
  background-color: #fff;
  border-radius: 100px;
  margin-bottom: 38px;

  @include tablet {
    display: block;
  }
}
.faqBtn {
  text-decoration:none;
  font-weight: bold;
}
.footerLogo {
  width: 50px;
  margin: auto;
  margin-left: 100px;
}