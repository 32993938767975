@import "@styles/mixins";

.container {
    width: 500px;
    height: 685px;
    font-family: 'Questrial';
    
    & .timer {
        width: 498px;
        height: 100px;
        background: #7EFBEF 0% 0% no-repeat padding-box;
        border: 2px solid #7EFBEF;
        border-radius: 5px;
        color: black;
        display: flex;
        justify-content: center;
        text-transform: uppercase;

        @include laptop {
            width: 248px;
            height: 70px;
        }   
    }

    & .detailsContainer {
        margin-top: 30px;
        width: 498px;
        height: 619px;

        background: #0000004D 0% 0% no-repeat padding-box;
        border: 2px solid #36FFF0;
        border-radius: 5px;
        padding: 15px;
        & .colContainer {
            width: 468px;
            height: 411px;
            gap: 20px;
            display: flex;
            & > .col {
                width: 220px;
                height: 411px;

                @include laptop {
                    height: max-content;
                }
            }

            @include laptop {
                width: 110px;
                height: max-content;
                gap: 8px;
            }
        }

        & .bracketButton {
            width: 468px;
            height: 104px;
            margin: 30px auto;
            font-size: 54px;
            color: #7EFBEF;
            border: 2px solid #7CF8EC;
            border-radius: 5px;

            @include laptop {
                width: 228px;
                height: 52px;
                font-size: 26px;
                margin-top: 0px;
            }
        }

        @include laptop {
            width: 248px;
            height: 295px;
            padding: 8px;
            margin-top: 15px;
        }

        &_map{
            padding: 0;
            border: 0;
            margin-top: 20px;
            margin-left: 20px;
            & > .cell {
                margin: auto;
            }
            height: unset;
            @include laptop {
                margin: auto;
            }
        }
    }
    
    @include laptop {
        width: 247px;
        height: 382px;
    }
    &_map{
        height: unset;
    }

}

.cell {
    width: 220px;
    /* UI Properties */
    background: #7CF8EC 0% 0% no-repeat padding-box;
    border: 1px solid #7EFBEF;
    border-radius: 5px;
    color: black;
    font-size: 31px;
    text-transform: uppercase;
    margin-bottom: 17px;
    padding-top: 15px;
    padding-bottom: 15px;
    & .title {
        margin: 15px auto;
        color: black;
        @include laptop {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    & .line {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        & img {
            width: 48px;
            height: 41px;
        }

        @include laptop {
            height: max-content !important;
        }
    }
    &_secondary {
        background: #000000 0% 0% no-repeat padding-box;
        color: white;

        & .title {
            margin: 15px auto;
            color: #7EFBEF;
        }
    }

    & .prizeList {
        color: white;
        font-size: 30px;
        text-align: left;
        margin: auto;
        padding: 10px;

        & > li {
            margin-top: 10px;
            @include laptop {
                margin-top: 5px;
            }
        }
        @include laptop {
            margin-top: 0px;
            font-size: 15px;
        }
           
    }

    & > .childContent {
        margin: auto;
    }

    @include laptop {
        width: 110px;
        font-size: 15px !important;
        margin-bottom: 6px;
        padding-top: 6px;
        padding-bottom: 8px;
        height: min-content !important;
    }

    &_dist {
        @include laptop {
            height: 151px !important;
        }
        @include tablet {
            height: 151px !important;
        }
    }
}

.result {
    @include laptop {
        margin-top: 50px;
    }
    @include tablet {
        margin-top: 50px;
    }
}