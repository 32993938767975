@import "@styles/mixins";

.wrapper {
  border: 3px solid #7EFBEF;
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  border-collapse: collapse;
  background-color: #000;
  &_nonRadius {
    border-radius: 0px;
  }
  &_fullRadius {
    border-radius: 15px;
  }

  @include laptop {
    border-width: 2px;
  }
}
.gameHeader {
  @include laptop {
    font-size: 10px !important;
    height: 13px !important;
  }
}
.gameRow {
  height: 46px !important;
}
.mainTable{
  display: inline-table;
  //height: 616px;
  border-radius: 25px;
  border-style: hidden;
  width: 100%;
  font-family: "Questrial";
  & thead {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  & tbody {
    display: block;
    & tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
  & tfoot {
    & tr {
      border-bottom: unset !important;
    }
  }
    &  tr {
      height: 88.51px;
      border-bottom: 1px solid var(--primary-color);
      & td {
        align-items: center;
        border: 0;
        vertical-align: middle;
        text-align: center;
      }
      & th {
        padding: 8px;
        align-items: center;
        border: 0;
        vertical-align: middle;
      }
      @include laptop {
        height: 30px;
      }
    }
  
   &_light {
    
    background-color: var(--primary-color);
    color: #333333;
    & > th {
      background-color: var(--secondary-color);
      color: white;
    }
    & > td {
      background-color: var(--primary-color);
      border-bottom: 1px solid var(--primary-gradient);
    }
    .columnDivider  {
      border-right: 1px solid #333333;
    }
    .clearRight {
      border-right: unset;
    }
   } 
    
   &_dark {
    
    background-color: var(--primary-gradient);
    color: #ffffff;
    & > td {
      background-color: var(--primary-gradient);
      border-bottom: 1px solid var(--primary-color);
      font-size: 20px;
    
      @include laptop{
        font-size: 16px;
      }
    }
    & > th {
      background-color: var(--primary-gradient);
      color: var(--primary-color);
    }
    .columnDivider {
      border-right: 2px solid var(--primary-color);
    }
    .clearRight {
      border-right: unset;
    }
  }

  @include laptop {
    height: 178px !important;
  }
}

  .field {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 22px;
    
    @include laptop{
      font-size: 18px;
    }
  }

  .secondaryRow {
    & td {
      background-color: #0F1D26
      
    }
  }
  .thinRow {
    
    height: 44px !important;

    @include laptop {
      height: 20px !important;
    }
  }
  .bolderHead {
    font-weight: 600;

    @include laptop {
      font-size: 13px;
    }
  }
  .footer {
    text-align: center;

    & img {
      width: 100px;
      margin-left: 24px;
    }
  }
  .pagination {
    .pageBtn {
      background-color: #000;
      color: white;
      font-size: 35px;

    
      @include laptop{
        font-size: 15px;
      }
      &_selected {
        color: var(--primary-color);
      }
      &_small {
        font-size: 20px !important;
      } 

    }
  }
