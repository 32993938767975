.modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 1;
  transition: 0.4s;
  background: #7EFBEF60 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: 100;
  &_lose {
    background: #FF367C60 0% 0% no-repeat padding-box;
  }
  &_notColor {
    background-color: unset;
  }
  overflow-y: auto;
}

.modalContent {
  background-image: url('../../../assets/images/popup-bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 2;
  width: 512px;
  height: 512px;
  transition: 0.4s;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding-top: 38px;
  padding-left: 26.5px;
  padding-right: 26.5px;
  overflow: hidden;

  border: 4px solid #7EFBEE;
  border-radius: 10px;

  &_secondary {
    background: var(--secondary-gradient);

    &::after {
      background: var(--primary-gradient);
    }
  }
}
