@import "@styles/mixins";

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 576px;
  height: 501px;
  padding: 20px;
  background: #000000 0% 0% no-repeat padding-box;
  border: 2px solid #7EFBEF;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 15px;
  &_lose {
    border-color: #FF367C
  }
  @include tablet {
    padding: 24px;
    padding-top: 36px;
  }
}
.logo {
  width: 84.17px;
  height: 84.17px;
  margin-top: 24.36px;
}
.gameTypeLabel {
  font-size: 21px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 49px;
  line-height: 2.25rem;
  text-align: center;
  color: #7EFBF0;
  text-transform: uppercase;
  font-family: 'Questrial', sans-serif;
  margin-top: 63.08px;
  &_lose {
    color: #FF367C;
  }
}
.desc {
  font-size:30px;
  letter-spacing: 0px;
  font-family: 'Questrial', sans-serif;
  margin-top: 58px;
}
.picksWrapper {
  margin-top: 1.125rem;
  display: grid;
  grid-template-columns: 1fr 2.5rem 1fr;

  @include tablet {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
}

.picksDivider {
  height: 100%;
  width: 0.0625rem;
  background-color: #fff;
  margin: 0 auto;

  @include tablet {
    display: none;
  }
}

.picks {
  & p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.tokens {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  & > div {
    margin-right: -1.3rem;

    @include tablet {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    & div {
      display: none;
    }
  }
}

.total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 0.0625rem solid #fff;
  padding-top: 0.375rem;
  margin-top: 0.375rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.btn {
  margin-top: 63px;
  width: 191px;
  height: 63px;
  background: #7EFBEF 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  font-family: 'Questrial', sans-serif;
  border-radius: 32px;
  font-size: 21px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  border: 0px;
  z-index: 10;
  &_lose {
    background-color: #FF367C;
  }
}

.trophies {
  margin-top: -80px;
  width: 576px;
}