.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  & > input {
    margin-top: 20px;
    width: 260px;
    height: 74px;
    font-size: 49px;
    text-align: center;
    background: #191919 0% 0% no-repeat padding-box;
    border: 2px solid #7EFBEF;
  }
  
  .label {
    margin-top: 35px;
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font-family: 'Questrial', sans-serif;
    font-size: 26px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
}

.errorTransaction {
  color: red;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: -30px;
}

.title {
  font-family: 'Questrial', sans-serif;
  font-size: 35px;
  line-height: 2.25rem;
  text-align: center;
  text-transform: uppercase;

  color: var(--primary-color);

  &_second {
    margin-top: 30px;
    margin-bottom: -20px;
  }
}

.description {
  margin-top: 0.75rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn {
  margin-top: 50px;
  height: 70px;
  font-size: 28px;
  font-family: 'Questrial', sans-serif;
  letter-spacing: 0px;
  color: #000000;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #000000;
  border-radius: 58px;
}

.formError {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: var(--error-color);
  text-align: center;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .title {
    margin-bottom: 1.5rem;
  }
}

.closeBtn {
  position: absolute;
  top:38px;
  right: 26.5px;
  background: transparent;
  border: none;
  z-index: inherit;
  cursor: pointer;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font-family: 'Questrial', sans-serif;
  font-size: 38px;
  
  letter-spacing: 0px;
  color: #FFFFFF;
}
.liner {
  margin-left: -30px;
  margin-top: 20px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .bar {

      width: 350px;
      height: 15px;
      background: transparent linear-gradient(90deg, #7EFBEF 0%, #00FFE1 100%, #3B5A57 100%) 0% 0% no-repeat padding-box;
      border-radius: 15px;
  }
  & > .info {
      width: 36px;
      height: 36px;
      color: #7EFBEF;
  }
}
.values {
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 60px;

}