@import "@styles/mixins";

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Questrial";
  color: black;

  &_game {
    font-weight: 600;
    font-size:54px;
    @include laptop {
        font-size: 26px;
    }
  }

}
