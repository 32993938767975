@import "@styles/mixins";

.wrapper {
  width: 100%;
  height: 634px;
  width: 100%;
  margin: auto;

  @include tablet {
    height: 310px;
  }

  @include laptop {
    height: 310px;
  }
}

.legend {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include tablet {
    margin-top: 18px;
    margin-bottom: 12px;
  }

  & p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & span {
    margin-left: 0.75rem;
    display: flex;
    align-items: center;
  }
}

.chart {
  width: 100%;
  height: 90%;

  @include tablet {
    height: 270px;
  }
}

:global {
  .recharts-cartesian-axis-tick-value {
    font-size: 0.75rem;

    @include laptop {
      font-size: 8px !important;
    }
  }

  .recharts-cartesian-grid-horizontal line:first-child,
  .recharts-cartesian-grid-vertical line:first-child {
    stroke: #02959f;
  }

  .recharts-xAxis .recharts-cartesian-axis-tick:nth-child(6) > text {
    fill: #02959f;
  }

  // .recharts-xAxis .recharts-cartesian-axis-tick:nth-child(7) {
  //   visibility: hidden;
  // }
}
